import { declareFont, defineFont, fontWeights, Font } from '../utils/FontUtils'

type Declarations = {
  Univers: Font
  UniversCondensed: Font
  UniversBold: Font
  UniversLTProRoman: Font
}

type Fonts = {
  Univers: string
  UniversCondensed: string
  UniversBold: string
  UniversLTProRoman: string
}

export const declaration: Declarations = {
  Univers: {
    basename: 'Univers',
    fontFamily: 'Univers',
    fontWeight: fontWeights.regular,
    fallback: 'sans-serif',
  },
  UniversCondensed: {
    basename: 'UniversCondensed',
    fontFamily: 'UniversCondensed',
    fontWeight: fontWeights.regular,
    fallback: 'sans-serif',
  },
  UniversBold: {
    basename: 'UniversBold',
    fontFamily: 'UniversBold',
    fontWeight: fontWeights.regular,
    fallback: 'sans-serif',
  },
  UniversLTProRoman: {
    basename: 'UniversLTProRoman',
    fontFamily: 'UniversLTProRoman',
    fontWeight: fontWeights.regular,
    fallback: 'sans-serif',
  },
}

export const fontsFaces = (): string => Object.values(declaration).map(declareFont).join(' ')

const fonts: Fonts = {
  Univers: defineFont(declaration.Univers),
  UniversCondensed: defineFont(declaration.UniversCondensed),
  UniversBold: defineFont(declaration.UniversBold),
  UniversLTProRoman: defineFont(declaration.UniversLTProRoman),
}

export default fonts
