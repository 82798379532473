import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import createSagaMiddleware from 'redux-saga'

import { reducers } from './index'
import rootSaga from './sagas'

export const rootReducer = combineReducers(reducers)

export type RootState = ReturnType<typeof rootReducer>

// use this in your components when you need a typed version of useSelector() hook
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

const makeStore = (initialState = {}) => {
  const sagaMiddleware = createSagaMiddleware()

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: [...getDefaultMiddleware({ thunk: false }), sagaMiddleware],
    devTools: process.env.NODE_ENV !== 'production',
  })

  sagaMiddleware.run(rootSaga)

  return store
}

export default makeStore
