import { all, put, takeLatest, select, call } from 'redux-saga/effects'

import * as configuration from '../../configuration'
import { actions, selectors } from '..'

import { actionTypes } from './types'

export default class AppSagas {
  static *init() {
    const isInit = yield select(selectors.app.isInit)

    if (!isInit) {
      yield put(actions.app.setIsInit({ isInit: true }))
    }
  }

  static *setTheme({ payload }: actionTypes.setTheme) {
    const theme = yield select(selectors.app.theme)
    console.log('AppSagas - setTheme : ', { payload, theme })
  }

  static *sendBase64Image({ payload }: actionTypes.sendBase64Image) {
    const { image, theme, mode } = payload

    const formData = new FormData()
    let url = `${configuration.app.apiUrl}/process`

    if (window && window.location.href.indexOf('.cn') >= 0) {
      url = `${configuration.app.apiUrlCN}/process`
      formData.append('cn', '1')
    } else {
      formData.append('cn', '0')
    }

    formData.append('image', image)
    formData.append('template', theme)
    formData.append('mode', mode)

    const sendImage = async () => {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      })
      return response.ok ? await response.json() : null
    }

    const result = yield call(sendImage)

    if (result) {
      yield put(actions.app.setShareUrl({ url: result.share_url }))
      yield put(actions.app.setImageRenditions(result.renditions))
    }
  }

  static *loop() {
    yield all([
      takeLatest(actions.app.init, this.init),
      takeLatest(actions.app.setTheme, this.setTheme),
      takeLatest(actions.app.sendBase64Image, this.sendBase64Image),
    ])
  }
}
