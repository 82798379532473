const preview = Number(process.env.CONTENTFUL_PREVIEW) === 1

export default {
  space: process.env.CONTENTFUL_SPACE_ID,
  environment: process.env.CONTENTFUL_ENVIRONMENT,
  host: preview ? 'preview.contentful.com' : 'cdn.contentful.com',
  accessToken: preview
    ? process.env.CONTENTFUL_ACCESS_TOKEN_PREVIEW
    : process.env.CONTENTFUL_ACCESS_TOKEN,
}
