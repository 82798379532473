import {
  actions as appActions,
  selectors as appSelectors,
  reducer as appReducer,
} from './app/redux'

export const actions = {
  app: appActions,
}

export const selectors = {
  app: appSelectors,
}

export const reducers = {
  app: appReducer,
}
