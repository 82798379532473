const colors = {
  blue: '#3C49CB',
  blueLight: '#ECEDFF',
  blueDark: '#131A4C',
  red: '#FE576B',
  orange: '#FF895A',
  pureBlack: '#000',
  pureWhite: '#FFF',
  yellow: '#FFBB00',
}

export default colors
