import Bugsnag, { Logger } from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const packageJson = require('../../package')

// const browserIgnoreErrors: [] = []

const productionDisableCallback = (callback: () => void) => {
  if (process.env.APP_ENV !== 'production') {
    callback()
  }
}

const bugsnagLoggerPrefix = `[bugsnag:${process.env.APP_ENV}]: `

const bugsnagLogger: Logger = {
  debug: (e) => productionDisableCallback(() => console.debug(`${bugsnagLoggerPrefix}${e}`)),
  info: (e) => productionDisableCallback(() => console.info(`${bugsnagLoggerPrefix}${e}`)),
  warn: (e) => productionDisableCallback(() => console.warn(`${bugsnagLoggerPrefix}${e}`)),
  error: (e) => productionDisableCallback(() => console.error(`${bugsnagLoggerPrefix}${e}`)),
}

let bugsnagClient: null | ReturnType<typeof Bugsnag.start> = null

if (process.env.BUGSNAG_API_KEY) {
  bugsnagClient = Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    appVersion: packageJson.version ? packageJson.version : 'undefined',
    logger: bugsnagLogger,
    releaseStage: process.env.APP_ENV,
    // TODO: beforeSend have been replaced by onError (https://github.com/bugsnag/bugsnag-js/blob/master/UPGRADING.md#options)
    // beforeSend: (report: any) => {
    //   // Disable reporting of errors with a given error message
    //   if (browserIgnoreErrors.some((ignoreError) => ignoreError.test(report.errorMessage))) {
    //     report.ignore()
    //   }
    // },
  })
}

export default bugsnagClient
