import React from 'react'

import bugsnagClient from '../../configuration/bugsnag'

let ErrorBoundary = null

if (bugsnagClient) {
  ErrorBoundary = bugsnagClient.getPlugin('react')?.createErrorBoundary(React)
}

const BugsnagWrapper = ErrorBoundary

export default BugsnagWrapper
