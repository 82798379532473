import fonts from './fonts'

const textStyles = {
  button: `
    ${fonts.UniversBold}
    font-size: 18px;
  `,
  count: `
    ${fonts.Univers}
    font-size: 54px;
  `,
  disclaimer: `
    ${fonts.UniversLTProRoman}
    font-size: 13px;
    line-height: 16px;
  `,
  safariPopup: `
    ${fonts.UniversLTProRoman}
    font-size: 16px;
    line-height: 20px;
  `,
}

export default textStyles
