export enum Screen {
  home = 'home',
  capture = 'capture',
  final = 'final',
}

export enum Theme {
  balenciaga = 'balenciaga',
  bling = 'bling',
  kitty = 'kitty',
}

export enum Mode {
  solo = 'solo',
  duo = 'duo',
}
