// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-debug-index-tsx": () => import("./../../../src/containers/Debug/index.tsx" /* webpackChunkName: "component---src-containers-debug-index-tsx" */),
  "component---src-containers-disclaimer-index-tsx": () => import("./../../../src/containers/Disclaimer/index.tsx" /* webpackChunkName: "component---src-containers-disclaimer-index-tsx" */),
  "component---src-containers-picture-index-tsx": () => import("./../../../src/containers/Picture/index.tsx" /* webpackChunkName: "component---src-containers-picture-index-tsx" */),
  "component---src-containers-root-index-tsx": () => import("./../../../src/containers/Root/index.tsx" /* webpackChunkName: "component---src-containers-root-index-tsx" */)
}

