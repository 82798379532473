import React, { FC } from 'react'
import { Provider } from 'react-redux'

import BugsnagWrapper from '../components/BugsnagWrapper/BugsnagWrapper'
import makeStore from '../redux/store'

type WrapWithProviderProps = {
  element: React.ReactNode
}

// Leave pure without React.memo or other HOCs
const wrapWithProvider: FC<WrapWithProviderProps> = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR template
  //  - it will be called only once in browser, when React mounts
  const store = makeStore()

  const provider = <Provider store={store}>{element}</Provider>

  if (BugsnagWrapper) {
    return <BugsnagWrapper>{provider}</BugsnagWrapper>
  }

  return provider
}

export default wrapWithProvider
