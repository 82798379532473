/*
 * Mixins
 */

/**
 * @typedef {Object.<string, any>} CSSDefinition
 * @typedef {Object.<string, CSSDefinition>} CSSMedia
 */

import { css } from 'styled-components'

/**
 * @param {string | string[] | null} trans
 * @param {string | string[] | null} duration
 * @param {string | string[] | null} easing
 * @returns {{transition: string}}
 */
export const transition = (
  trans: string | string[] = 'all',
  duration: string | string[] = '200ms',
  easing: string | string[] = 'ease'
): { transition: string } => ({
  transition: Array.isArray(trans)
    ? trans
        .map((transIt, transIndex) => {
          const transDuration = Array.isArray(duration)
            ? duration[transIndex] ?? duration[0]
            : duration
          const transEasing = Array.isArray(easing) ? easing[transIndex] ?? easing[0] : easing

          return `${transIt} ${transDuration} ${transEasing}`
        })
        .join(', ')
    : `${trans} ${duration} ${easing}`,
})

/**
 * @param {string} hex
 * @param {number} opacity
 * @returns {string}
 */
export const alpha = (hex: string, opacity = 1): string => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (opacity) {
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }
  return `rgb(${r}, ${g}, ${b})`
}

export const svgGlobal = (
  color: string,
  transitionProperties: { duration: string; effect: string }
) => ({
  '& *[fill]:not([fill="none"]):not(.no-fill)': {
    fill: color,
    extend: transitionProperties
      ? transition('fill', transitionProperties.duration, transitionProperties.effect)
      : null,
  },
  '& *[stroke]:not([stroke="none"]):not(.no-stroke)': {
    stroke: color,
    extend: transitionProperties
      ? transition('stroke', transitionProperties.duration, transitionProperties.effect)
      : null,
  },
  '& path': {
    extend: transitionProperties
      ? transition('fill', transitionProperties.duration, transitionProperties.effect)
      : null,
  },
})

export const svgColor = svgGlobal

/**
 * @param {CSSDefinition} cssDefs
 */
export const svgForce = (cssDefs: CSSStyleDeclaration) => ({
  '& > *, & > * > *': {
    ...cssDefs,
  },
})

/**
 * @param {string} color
 * @param {{}} font
 */
export const placeholder = (color: string, font = {}) => {
  /**
   * @param {string} c
   * @param {{}} f
   */
  const getPlaceHolder = (c: string, f = {}) => ({
    '& [placeholder], &::placeholder': {
      color: c,
      ...f,
    },
    '&::-webkit-input-placeholder': {
      color: c,
      ...f,
    },
    '&:-moz-placeholder': {
      color: c,
      ...f,
    },
    '&::-moz-placeholder': {
      color: c,
      ...f,
    },
    '&:-ms-input-placeholder': {
      color: c,
      ...f,
    },
    '&::-ms-input-placeholder': {
      color: c,
      ...f,
    },
  })

  if (typeof color === 'function') {
    return {
      ...getPlaceHolder(color, font),
    }
  }

  return getPlaceHolder(color, font)
}

/**
 * @param {number} width
 * @param {number} height
 */
export const aspectRatio = (width: number, height: number) => css`
  width: 100%;
  padding-top: ${(height / width) * 100}%;
  position: relative;
  height: 0;
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

/**
 * @param {number} dimension
 * @param {number} base
 */
export const pxToRem = (dimension: number, base = 16) => dimension / base

/**
 * @param {number} dimension
 * @param {string} unit
 */
export const numberToCSSUnit = (dimension: number, unit = 'px') => `${dimension}${unit}`

/**
 * @param {string} dimension
 */
export const minMediaQ = (dimension: number) => `(min-width: ${numberToCSSUnit(dimension, 'px')})`

/**
 * @param {string} dimension
 */
export const maxMediaQ = (dimension: number) => `(max-width: ${numberToCSSUnit(dimension, 'px')})`

/**
 * @param {number} min
 * @param {number} max
 * @param {array} more
 */
export const mq = (
  min: null | number = null,
  max: null | number = null,
  more: null | [] = null
) => {
  const queries = []

  if (min) {
    queries.push(minMediaQ(min))
  }
  if (max) {
    queries.push(maxMediaQ(max))
  }
  if (more) {
    queries.push(...more)
  }

  return `${queries.join(' and ')}`
}
