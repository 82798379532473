import { DefaultTheme } from 'styled-components'

import colors from './colors'

const theme1: DefaultTheme = {
  colors: {
    primary: colors.blue,
  },
}

const theme2: DefaultTheme = {
  colors: {
    primary: colors.orange,
  },
}

const theme3: DefaultTheme = {
  colors: {
    primary: colors.yellow,
  },
}

export enum themesKey {
  theme1 = 'theme1',
  theme2 = 'theme2',
  theme3 = 'theme3',
}

export const themes: { [key in themesKey]: DefaultTheme } = {
  [themesKey.theme1]: theme1,
  [themesKey.theme2]: theme2,
  [themesKey.theme3]: theme3,
}
