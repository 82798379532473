import { createSlice } from '@reduxjs/toolkit'

import { themesKey } from '../../theme'
import type { RootState } from '../store'
import { Mode, Screen } from '../../types/global'

import { actionTypes } from './types'

type AppState = {
  isInit: boolean
  theme: themesKey
  imageRenditions?: {
    portrait: string
    landscape: string
  }
  shareUrl?: string
  screen: Screen
  photoThemeIndex: number
  photoMode: Mode
}

//
// Initial state
//

const initialState: AppState = {
  isInit: false,
  theme: themesKey.theme1,
  screen: Screen.home,
  photoThemeIndex: 0,
  photoMode: Mode.solo,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    init: () => undefined,
    restart: (state) => {
      state.shareUrl = undefined
      state.imageRenditions = undefined
      state.screen = Screen.capture
    },
    sendBase64Image: (_state, _action: actionTypes.sendBase64Image) => undefined,
    setIsInit: (state, action: actionTypes.setIsInit) => {
      const { isInit } = action.payload
      state.isInit = isInit
    },
    setImageRenditions: (state, { payload }: actionTypes.setImageRenditions) => {
      state.imageRenditions = payload
    },
    setPhotoMode: (state, { payload }: actionTypes.setPhotoMode) => {
      state.photoMode = payload.mode
    },
    setPhotoThemeIndex: (state, { payload }: actionTypes.setPhotoThemeIndex) => {
      state.photoThemeIndex = payload.index
    },
    setShareUrl: (state, { payload }: actionTypes.setShareUrl) => {
      state.shareUrl = payload.url
    },
    setScreen: (state, { payload }: actionTypes.setScreen) => {
      state.screen = payload.screen
    },
    setTheme: (state, { payload }: actionTypes.setTheme) => {
      const { theme } = payload
      state.theme = theme
    },
  },
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state.app
const isInit = (state: RootState) => root(state).isInit
const theme = (state: RootState) => root(state).theme
const imageRenditions = (state: RootState) => root(state).imageRenditions
const shareUrl = (state: RootState) => root(state).shareUrl
const screen = (state: RootState) => root(state).screen
const photoMode = (state: RootState) => root(state).photoMode
const photoThemeIndex = (state: RootState) => root(state).photoThemeIndex

export const selectors = {
  isInit,
  theme,
  imageRenditions,
  shareUrl,
  screen,
  photoMode,
  photoThemeIndex,
}
